import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, 
  timeout: 100000
})


service.interceptors.request.use(
  config => {
    // if (store.getters.token) {
    //   config.headers['X-Token'] = getToken()
    // }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    const { code, data } = res
    console.log('code: ' + code)
    if (code === 0) {
      return data
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service